import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const ComingSoonPage = () => {
  const navigate = useNavigate();
  const darkBrownGradientRef = useRef(null);
  const lightBrownGradientRef = useRef(null);
  const canvasRef = useRef(null);
  const [isBlackAndWhite, setIsBlackAndWhite] = useState(false);

  const navigateToHome = () => {
    navigate('/');
  };

  // Throttle function to optimize resize event
  const throttle = (func, limit) => {
    let inThrottle;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let mouse = { x: 0, y: 0 };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const createGrid = () => {
      const gridSize = 30;
      const rows = Math.ceil(canvas.height / gridSize);
      const cols = Math.ceil(canvas.width / gridSize);
      const grid = [];

      for (let y = 0; y <= rows; y++) {
        for (let x = 0; x <= cols; x++) {
          grid.push({
            x: x * gridSize,
            y: y * gridSize,
            baseX: x * gridSize,
            baseY: y * gridSize,
          });
        }
      }

      return { grid, rows, cols };
    };

    let { grid, rows, cols } = createGrid();

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.strokeStyle = isBlackAndWhite ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)';
      ctx.lineWidth = 0.5;

      for (let i = 0; i < grid.length; i++) {
        const point = grid[i];
        const distX = mouse.x - point.baseX;
        const distY = mouse.y - point.baseY;
        const distance = Math.sqrt(distX * distX + distY * distY);
        const maxDistance = 150;

        if (distance < maxDistance) {
          const force = (1 - distance / maxDistance) * 0.2;
          point.x = point.baseX - distX * force;
          point.y = point.baseY - distY * force;
        } else {
          point.x += (point.baseX - point.x) * 0.1;
          point.y += (point.baseY - point.y) * 0.1;
        }

        // Draw horizontal lines
        if (i < grid.length - 1 && (i + 1) % (cols + 1) !== 0) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + 1].x, grid[i + 1].y);
        }

        // Draw vertical lines
        if (i + cols + 1 < grid.length) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + cols + 1].x, grid[i + cols + 1].y);
        }
      }

      ctx.stroke();
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    };

    const handleResize = throttle(() => {
      resizeCanvas();
      ({ grid, rows, cols } = createGrid());
    }, 200); // Throttle resize event

    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (darkBrownGradientRef.current) {
        darkBrownGradientRef.current.style.transform = `rotate(${scrollY * 0.1}deg)`;
      }
      if (lightBrownGradientRef.current) {
        lightBrownGradientRef.current.style.transform = `rotate(${-scrollY * 0.1}deg)`;
      }
    };

    resizeCanvas();
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isBlackAndWhite]);

  const toggleBlackAndWhite = () => {
    setIsBlackAndWhite(!isBlackAndWhite);
  };

  const baseStyles = "transition-all duration-300";
  const colorStyles = isBlackAndWhite ? "bg-white text-black" : "bg-black text-white";

  return (
    <div className={`min-h-screen flex flex-col relative overflow-hidden font-helvetica ${baseStyles} ${colorStyles}`}>
      <canvas ref={canvasRef} className="fixed inset-0 pointer-events-none" style={{ zIndex: 0 }} />

      {!isBlackAndWhite && (
        <>
          <div
            ref={darkBrownGradientRef}
            className="fixed -top-1/4 -right-1/4 w-2/3 h-2/3 bg-[#964B00] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 1 }}
          ></div>
          <div
            ref={lightBrownGradientRef}
            className="fixed -bottom-1/3 -left-1/4 w-3/4 h-3/4 bg-[#FFC499] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 1 }}
          ></div>
        </>
      )}

      <div className="relative z-10 flex flex-col min-h-screen">
        <header className="p-6 flex justify-between items-center">
          <div className="flex items-center">
            <svg className="w-8 h-8 mr-2" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              {/* Add your Lawdie logo SVG here */}
            </svg>
            <span className="text-xl font-bold cursor-pointer" onClick={navigateToHome}>
              Lawdie
            </span>
          </div>
          <nav>
            <ul className="flex space-x-6 items-center">
              <li>
                <button className="hover:underline">Company</button>
              </li>
              <li>
                <button className="hover:underline">Services</button>
              </li>
              <li>
              <button className="hover:underline">
                Lawyer Search <span className={`${isBlackAndWhite ? "bg-gradient-to-r from-gray-600 to-gray-400 text-transparent bg-clip-text" : "bg-gradient-to-r from-[#FFC499] to-[#FFC499] text-transparent bg-clip-text"}`}>(BETA)</span>
              </button>
              </li>
              <li>
                <button className="border px-4 py-1 rounded-full hover:bg-gray-200 transition duration-300">Sign up</button>
              </li>
            </ul>
          </nav>
        </header>

        <main className="flex-grow flex flex-col justify-center items-center px-4 py-60">
            <h1 className="text-8xl font-bold mb-16 text-center">
            <span>Coming</span>
            <span className={`bg-gradient-to-r ${isBlackAndWhite ? "from-gray-700 via-gray-500 to-gray-300" : "from-[#5D341A] to-[#FFC499]"} text-transparent bg-clip-text`}> Soon ...</span>
            </h1>
          <div className="flex w-full max-w-3xl mb-20 space-x-4">
            <input
              type="email"
              placeholder="Email"
              className={`w-2/3 px-6 py-4 rounded-full focus:outline-none ${baseStyles} ${
                isBlackAndWhite ? "bg-gray-200 text-black" : "bg-gray-800 text-white"
              }`}
            />
            <button
              className={`w-1/3 px-6 py-4 rounded-full focus:outline-none ${baseStyles} ${
                isBlackAndWhite ? "bg-gray-800 text-white" : "bg-[#2E1A0E] text-white"
              }`}
            >
              Notify me
            </button>
          </div>
        </main>

        <div 
          className={`w-full h-[2px] ${
            isBlackAndWhite 
              ? "bg-black" 
              : "bg-[#3d3326]"
          }`}
          style={{
            backgroundImage: `linear-gradient(to right, ${isBlackAndWhite ? 'black' : '#3d3326'} 50%, rgba(255, 255, 255, 0) 0%)`,
            backgroundPosition: 'top',
            backgroundSize: '20px 2px',
            backgroundRepeat: 'repeat-x'
          }}
        ></div>

        <footer className={`py-12 px-6 ${isBlackAndWhite ? "bg-gray-100 text-gray-800" : "bg-[#0F0B07] text-gray-300"}`}>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
            <div>
              <h3 className={`font-bold mb-4 ${isBlackAndWhite ? "text-gray-900" : "text-white"}`}>Company</h3>
              <ul className="space-y-2">
                <li><button className="hover:underline">About us</button></li>
                <li><button className="hover:underline">Team</button></li>
                <li><button className="hover:underline">Careers</button></li>
              </ul>
            </div>
            <div>
              <h3 className={`font-bold mb-4 ${isBlackAndWhite ? "text-gray-900" : "text-white"}`}>Services</h3>
              <ul className="space-y-2">
                <li><button className="hover:underline">Branding</button></li>
                <li><button className="hover:underline">Web development</button></li>
                <li><button className="hover:underline">Digital marketing</button></li>
                <li><button className="hover:underline">Mobile app</button></li>
                <li><button className="hover:underline">SEO</button></li>
                <li><button className="hover:underline">User testing</button></li>
              </ul>
            </div>
            <div>
              <h3 className={`font-bold mb-4 ${isBlackAndWhite ? "text-gray-900" : "text-white"}`}>Resources</h3>
              <ul className="space-y-2">
                <li><button className="hover:underline">Blog</button></li>
                <li><button className="hover:underline">Case study</button></li>
                <li><button className="hover:underline">Testimonials</button></li>
              </ul>
            </div>
            <div>
              <h3 className={`font-bold mb-4 ${isBlackAndWhite ? "text-gray-900" : "text-white"}`}>Follow us</h3>
              <ul className="space-y-2">
                <li><button className="hover:underline">LinkedIn</button></li>
              </ul>
            </div>
            <div>
              <div className="flex items-center mb-4">
                <svg className="w-8 h-8 mr-2" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  {/* Add your Lawdie logo SVG here */}
                </svg>
                <span className={`font-bold ${isBlackAndWhite ? "text-gray-900" : "text-white"}`}>Lawdie</span>
              </div>
              <p className="text-sm mb-2">Get latest updates</p>
              <div className="flex">
                <input 
                  type="email" 
                  placeholder="Your email" 
                  className={`w-full px-3 py-2 rounded focus:outline-none text-sm ${
                    isBlackAndWhite ? "bg-white text-gray-800 border border-gray-300" : "bg-gray-800 text-white"
                  }`} 
                />
              </div>
            </div>
          </div>
          <div className="mt-12 text-center text-xs">
            Created by Lawdie Inc.
          </div>
        </footer>
      </div>
    </div>
  );
};

export default ComingSoonPage;