import React, { useState } from 'react';
import { 
  ArrowLeft, 
  Upload, 
  Download, 
  FileText, 
  Clock, 
  CheckCircle, 
  AlertCircle,
  ChevronRight,
  Plus,
  X
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Sample data for demo purposes - remove in production
const defaultLien = {
  lienholderName: "Atlanta Medical Center",
  caseNumber: "L-2024-001",
  amount: 75000,
  filingDate: "2024-01-15",
  expiryDate: "2024-07-15",
  status: "Active",
  type: "Medical",
  propertyAddress: "123 Main St, Atlanta, GA",
  debtor: "John Smith"
};

const LienDetailPage = ({ lien = defaultLien, onBack = () => {} }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [documents, setDocuments] = useState([
    { id: 1, name: 'Lien Notice.pdf', type: 'PDF', date: '2024-03-15', size: '2.4 MB' },
    { id: 2, name: 'Property Assessment.docx', type: 'DOCX', date: '2024-03-14', size: '1.1 MB' },
    { id: 3, name: 'Court Filing.pdf', type: 'PDF', date: '2024-03-13', size: '3.7 MB' }
  ]);

  const workflowSteps = [
    { id: 1, title: 'Initial Filing', status: 'completed', date: '2024-01-15', description: 'Lien documentation submitted to court' },
    { id: 2, title: 'Document Review', status: 'completed', date: '2024-01-20', description: 'All documents verified and approved' },
    { id: 3, title: 'Client Notification', status: 'in-progress', date: null, description: 'Notifying all relevant parties' },
    { id: 4, title: 'Payment Processing', status: 'pending', date: null, description: 'Awaiting payment confirmation' },
    { id: 5, title: 'Final Resolution', status: 'pending', date: null, description: 'Completing lien resolution process' }
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadFile(file);
      setShowUploadModal(true);
    }
  };

  const handleUploadConfirm = () => {
    if (uploadFile) {
      const newDocument = {
        id: documents.length + 1,
        name: uploadFile.name,
        type: uploadFile.name.split('.').pop().toUpperCase(),
        date: new Date().toISOString().split('T')[0],
        size: `${(uploadFile.size / (1024 * 1024)).toFixed(1)} MB`
      };
      setDocuments([...documents, newDocument]);
    }
    setShowUploadModal(false);
    setUploadFile(null);
  };

  const handleAddDocument = () => {
    navigate('/dashboard/liens/document/new');
  };

  const getStepIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="w-6 h-6 text-black" />;
      case 'in-progress':
        return <Clock className="w-6 h-6 text-[#3d3731]" />;
      default:
        return <AlertCircle className="w-6 h-6 text-[#a39b8f]" />;
    }
  };

  return (
    <div className="min-h-screen bg-[#d8d1c7] flex flex-col">
      {/* Header */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f] p-6">
        <button 
          onClick={onBack}
          className="flex items-center text-[#3d3731] hover:text-black mb-4"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Liens
        </button>
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold text-black mb-2">{lien.lienholderName}</h1>
            <p className="text-[#3d3731]">Case #{lien.caseNumber}</p>
          </div>
          <button className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150">
            Mark as Resolved
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f]">
        <div className="px-6">
          <div className="flex space-x-6">
            {['overview', 'documents', 'workflow'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`py-4 px-2 -mb-px text-sm font-medium ${
                  activeTab === tab
                    ? 'border-b-2 border-black text-black'
                    : 'text-[#3d3731] hover:text-black'
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-auto p-6">
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h2 className="text-lg font-semibold text-black mb-4">Lien Details</h2>
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="text-[#3d3731]">Amount:</span>
                  <span className="font-semibold text-black">${lien.amount.toLocaleString()}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[#3d3731]">Filing Date:</span>
                  <span className="text-black">{new Date(lien.filingDate).toLocaleDateString()}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[#3d3731]">Expiry Date:</span>
                  <span className="text-black">{new Date(lien.expiryDate).toLocaleDateString()}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[#3d3731]">Type:</span>
                  <span className="text-black">{lien.type}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[#3d3731]">Debtor:</span>
                  <span className="text-black">{lien.debtor}</span>
                </div>
              </div>
            </div>

            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h2 className="text-lg font-semibold text-black mb-4">Property Information</h2>
              <div className="space-y-4">
                <p className="text-[#3d3731]">{lien.propertyAddress}</p>
                <div className="mt-4">
                  <img 
                    src="/api/placeholder/400/200" 
                    alt="Property" 
                    className="w-full h-48 object-cover rounded-lg"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'documents' && (
          <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-semibold text-black">Documents</h2>
              <div className="flex space-x-3">
                <label className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150 cursor-pointer">
                  <input 
                    type="file" 
                    className="hidden" 
                    onChange={handleFileUpload}
                  />
                  <Upload className="w-4 h-4 inline-block mr-2" />
                  Upload
                </label>
                <button 
                  onClick={handleAddDocument}
                  className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150"
                >
                  <Plus className="w-4 h-4 inline-block mr-2" />
                  Add Document
                </button>
              </div>
            </div>
            <div className="divide-y divide-[#a39b8f]">
              {documents.map((doc) => (
                <div key={doc.id} className="py-4 flex items-center justify-between">
                  <div className="flex items-center">
                    <FileText className="w-5 h-5 mr-3 text-[#3d3731]" />
                    <div>
                      <p className="font-medium text-black">{doc.name}</p>
                      <p className="text-sm text-[#3d3731]">{doc.size} • {doc.date}</p>
                    </div>
                  </div>
                  <button className="text-[#3d3731] hover:text-black">
                    <Download className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'workflow' && (
          <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
            <h2 className="text-lg font-semibold text-black mb-6">Workflow Progress</h2>
            <div className="space-y-8">
              {workflowSteps.map((step, index) => (
                <div key={step.id} className="relative">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {getStepIcon(step.status)}
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium text-black">{step.title}</h3>
                      <p className="mt-1 text-[#3d3731]">{step.description}</p>
                      {step.date && (
                        <p className="mt-1 text-sm text-[#3d3731]">
                          Completed on {new Date(step.date).toLocaleDateString()}
                        </p>
                      )}
                    </div>
                  </div>
                  {index < workflowSteps.length - 1 && (
                    <div className="absolute left-3 top-8 h-16 w-px bg-[#a39b8f]" />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Upload Modal */}
      {showUploadModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#e9e4dc] p-6 rounded-lg max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-black">Upload Document</h3>
              <button 
                onClick={() => setShowUploadModal(false)}
                className="text-[#3d3731] hover:text-black"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="mb-4">
              <p className="text-[#3d3731]">Selected file: {uploadFile?.name}</p>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowUploadModal(false)}
                className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2]"
              >
                Cancel
              </button>
              <button
                onClick={handleUploadConfirm}
                className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731]"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LienDetailPage;