import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { supabase } from './backend/supabase';
import AuthCallback from './components/auth/AuthCallback';
import AttorneyProfileCompletion from './components/signup/AttorneyProfileCompletion';
import LandingPage from './LandingPage';
import ComingSoonPage from './ComingSoonPage';
import Home from './Home';
import Dashboard from './Dashboard';
import SignupPage from './SignupPage';
import RegistrationForm from './components/signup/RegistrationForm';
import LoginForm from './components/signup/LoginForm';
import LinkedInCallback from './LinkedInCallback';
import LienDocManager from './components/lien/LienDocManager';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    // Check current session
    const checkSession = async () => {
      try {
        const { data: { session: currentSession }, error } = await supabase.auth.getSession();
        
        if (error) throw error;
        
        setSession(currentSession);
        if (!currentSession) {
          navigate('/signup/login');
        }
      } catch (error) {
        console.error('Session check error:', error);
        navigate('/signup/login');
      } finally {
        setLoading(false);
      }
    };

    checkSession();

    // Set up auth state listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, newSession) => {
      setSession(newSession);
      if (!newSession) {
        navigate('/signup/login');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#d8d1c7] flex items-center justify-center">
        <span className="text-[#3d3731]">Loading...</span>
      </div>
    );
  }

  return session ? children : null;
};

function App() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Initialize Supabase auth
    const initializeAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;
        console.log('Auth initialized', session ? 'with session' : 'without session');
      } catch (error) {
        console.error('Auth initialization error:', error);
      } finally {
        setInitialized(true);
      }
    };

    initializeAuth();
  }, []);

  if (!initialized) {
    return (
      <div className="min-h-screen bg-[#d8d1c7] flex items-center justify-center">
        <span className="text-[#3d3731]">Initializing...</span>
      </div>
    );
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signup/register" element={<RegistrationForm />} />
          <Route path="/signup/login" element={<LoginForm />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          
          {/* Profile completion route */}
          <Route 
            path="/signup/complete-profile" 
            element={
              <ProtectedRoute>
                <AttorneyProfileCompletion />
              </ProtectedRoute>
            } 
          />
          
          {/* Protected routes */}
          <Route 
            path="/dashboard/*" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dashboard/liens/:id" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dashboard/liens/document/new" 
            element={
              <ProtectedRoute>
                <LienDocManager />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;