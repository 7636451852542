import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './backend/supabase';
import { 
  Home, 
  Layers, 
  CheckSquare, 
  PieChart, 
  Users, 
  Bell, 
  HelpCircle, 
  Settings, 
  ChevronDown,
  LogOut,
  Filter,
  Search,
  MoreVertical,
  Trash2,
  Edit2
} from 'lucide-react';
import UserAvatar from './components/ui/UserAvatar';
import HomeComponent from './Home';
import LienManagement from './components/lien/LienManagement';

const SidebarItem = ({ icon: Icon, text, count, active, onClick }) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center px-6 py-3 ${
      active ? 'bg-[#d8d1c7] text-black' : 'text-[#3d3731] hover:bg-[#e9e4dc]'
    } transition-colors duration-150`}
  >
    <Icon className="w-5 h-5 mr-3" />
    <span className="text-sm font-medium">{text}</span>
    {count !== undefined && (
      <span className="ml-auto bg-[#a39b8f] text-black rounded-full px-2 py-1 text-xs">{count}</span>
    )}
  </button>
);

const TopBar = () => (
  <div className="flex justify-between items-center py-4 px-8 border-b border-[#a39b8f] bg-[#e9e4dc]">
    <h1 className="text-2xl font-semibold text-black">Referral Hub</h1>
    <div className="flex space-x-2">
      <button className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150">
        Import
      </button>
      <button className="px-4 py-2 bg-black text-[#e9e4dc] rounded-md hover:bg-[#3d3731] transition-colors duration-150">
        + Add Referral
      </button>
    </div>
  </div>
);

const TabBar = ({ activeTab, setActiveTab }) => (
  <div className="flex border-b border-[#a39b8f] bg-[#e9e4dc] px-8">
    {['Public', 'Private', 'Favorites', 'Custom'].map((tab) => (
      <button
        key={tab}
        onClick={() => setActiveTab(tab)}
        className={`px-4 py-4 ${
          activeTab === tab
            ? 'border-b-2 border-black text-black'
            : 'text-[#3d3731] hover:text-black'
        } transition-colors duration-150 text-sm font-medium`}
      >
        {tab}
      </button>
    ))}
  </div>
);

const ReferralTable = ({ data }) => (
  <div className="overflow-auto flex-grow">
    <table className="w-full">
      <thead>
        <tr className="text-left text-[#3d3731] bg-[#d8d1c7]">
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm Contact</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Phone Number</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Email</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Status</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Actions</th>
        </tr>
      </thead>
      <tbody className="bg-[#e9e4dc] divide-y divide-[#a39b8f]">
        {data.map((row, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap text-[#3d3731]">{row.firm}</td>
            <td className="px-6 py-4 whitespace-nowrap text-[#3d3731]">{row.contact}</td>
            <td className="px-6 py-4 whitespace-nowrap text-[#3d3731]">{row.phone}</td>
            <td className="px-6 py-4 whitespace-nowrap text-[#3d3731]">{row.email}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                row.status === 'Active'
                  ? 'bg-[#c5beb2] text-black'
                  : 'bg-[#a39b8f] text-[#3d3731]'
              }`}>
                {row.status}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button className="text-gray-400 hover:text-gray-500 mr-2">
                <Edit2 size={20} />
              </button>
              <button className="text-gray-400 hover:text-gray-500">
                <Trash2 size={20} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Pagination = () => (
  <div className="flex justify-between items-center px-6 py-3 bg-[#e9e4dc] border-t border-[#a39b8f]">
    <p className="text-sm text-[#3d3731]">
      Showing data <span className="font-medium">1</span> to <span className="font-medium">8</span> of <span className="font-medium">256K</span> entries
    </p>
    <div className="flex space-x-1">
      {[1, 2, 3, 4, '...', 40].map((page, index) => (
        <button
          key={index}
          className={`px-3 py-1 ${
            page === 1 ? 'bg-black text-[#e9e4dc]' : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2]'
          } border border-[#a39b8f] rounded-md text-sm`}
        >
          {page}
        </button>
      ))}
    </div>
  </div>
);

const Dashboard = () => {
  const [activeItem, setActiveItem] = useState('Home');
  const [activeTab, setActiveTab] = useState('Public');
  const [nodeMode, setNodeMode] = useState(false);
  const mainContainerRef = useRef(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const navigate = useNavigate();

  const userMenuRef = useRef(null);

  const referralData = [
    { firm: 'Jane Cooper', contact: 'Microsoft', phone: '(225) 555-0118', email: 'jane@microsoft.com', status: 'Active' },
    { firm: 'Jane Cooper', contact: 'Microsoft', phone: '(225) 555-0118', email: 'jane@microsoft.com', status: 'Active' },
    { firm: 'Kristin Watson', contact: 'Facebook', phone: '(704) 555-0127', email: 'kristin@facebook.com', status: 'Inactive' },
    { firm: 'Marvin McKinney', contact: 'Tesla', phone: '(252) 555-0126', email: 'marvin@tesla.com', status: 'Active' },
    { firm: 'Kristin Watson', contact: 'Facebook', phone: '(704) 555-0127', email: 'kristin@facebook.com', status: 'Inactive' },
    { firm: 'Kathryn Murphy', contact: 'Microsoft', phone: '(406) 555-0120', email: 'kathryn@microsoft.com', status: 'Active' },
    { firm: 'Jacob Jones', contact: 'Yahoo', phone: '(208) 555-0112', email: 'jacob@yahoo.com', status: 'Active' },
    { firm: 'Kristin Watson', contact: 'Facebook', phone: '(704) 555-0127', email: 'kristin@facebook.com', status: 'Inactive' },
  ];

  useEffect(() => {
    getUser();

    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getUser = async () => {
    try {
      const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
      
      if (authError) throw authError;

      if (authUser) {
        const { data: profileData, error: profileError } = await supabase
          .from('users')
          .select('*')
          .eq('email', authUser.email)
          .single();

        if (profileError && profileError.code !== 'PGRST116') {
          throw profileError;
        }

        setUser({
          ...authUser,
          ...profileData
        });
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#d8d1c7] flex items-center justify-center">
        <span className="text-[#3d3731]">Loading...</span>
      </div>
    );
  }

  const UserMenu = () => (
    <div className="p-4 border-t border-[#a39b8f] relative" ref={userMenuRef}>
      <button
        onClick={() => setShowUserMenu(!showUserMenu)}
        className="w-full flex items-center justify-between hover:bg-[#f0ece4] p-2 rounded-md transition-colors duration-150"
      >
        <div className="flex items-center">
          <UserAvatar 
            src={user?.user_metadata?.picture} 
            alt={`${user?.user_metadata?.full_name || 'User'} avatar`}
            size={40}
          />
          <div className="ml-3">
            <p className="font-medium text-black">
              {user?.user_metadata?.full_name || 'User'}
            </p>
            <p className="text-sm text-[#3d3731]">{user?.email}</p>
          </div>
        </div>
        <ChevronDown className="w-4 h-4 text-[#3d3731]" />
      </button>

      {showUserMenu && (
        <div className="absolute bottom-full left-0 right-0 mb-1 bg-[#e9e4dc] border border-[#a39b8f] rounded-md shadow-lg">
          <div className="p-2">
            <button
              onClick={handleSignOut}
              className="w-full flex items-center px-4 py-2 text-[#3d3731] hover:bg-[#f0ece4] rounded-md transition-colors duration-150"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const Sidebar = () => (
    <div className="w-64 bg-[#e9e4dc] h-screen shadow-sm flex flex-col">
      <div className="p-6 flex items-center">
        <div className="w-8 h-8 bg-black rounded mr-3 flex items-center justify-center">
          <span className="text-[#e9e4dc] font-bold">L</span>
        </div>
        <h2 className="text-xl font-semibold text-black">Lawdie</h2>
      </div>
      
      <nav className="flex-grow mt-2">
        <SidebarItem icon={Home} text="Home" active={activeItem === 'Home'} onClick={() => setActiveItem('Home')} />
        <SidebarItem icon={Layers} text="Projects" active={activeItem === 'Projects'} onClick={() => setActiveItem('Projects')} />
        <SidebarItem icon={CheckSquare} text="Tasks" count={10} active={activeItem === 'Tasks'} onClick={() => setActiveItem('Tasks')} />
        <SidebarItem icon={PieChart} text="Liens" active={activeItem === 'Liens'} onClick={() => setActiveItem('Liens')} />
        <SidebarItem icon={Users} text="Users" active={activeItem === 'Users'} onClick={() => setActiveItem('Users')} />
      </nav>
      
      <div className="mt-auto border-t border-[#a39b8f]">
        <SidebarItem icon={Bell} text="Notifications" count={10} active={activeItem === 'Notifications'} onClick={() => setActiveItem('Notifications')} />
        <SidebarItem icon={HelpCircle} text="Support" active={activeItem === 'Support'} onClick={() => setActiveItem('Support')} />
        <SidebarItem icon={Settings} text="Settings" active={activeItem === 'Settings'} onClick={() => setActiveItem('Settings')} />
      </div>

      <UserMenu />
    </div>
  );

  return (
    <div 
      ref={mainContainerRef}
      className="flex h-screen bg-[#e9e4dc] overflow-auto"
    >
      <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />
      <div className="flex-1 flex flex-col overflow-hidden ml-4">
        {activeItem === 'Home' ? (
          <HomeComponent />
        ) : activeItem === 'Liens' ? (
          <LienManagement /> 
        ) : (
          <div className="flex flex-col h-full">
            <TopBar />
            <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="flex-grow flex flex-col bg-[#e9e4dc] rounded-lg shadow-sm mt-4 mx-4 overflow-hidden">
              {/* Node mode checkbox */}
              <div className="flex justify-end p-4 border-b border-[#a39b8f]">
                <label className="flex items-center space-x-2 text-sm text-[#3d3731] cursor-pointer">
                  <input
                    type="checkbox"
                    checked={nodeMode}
                    onChange={(e) => setNodeMode(e.target.checked)}
                    className="h-4 w-4 rounded border-[#a39b8f] text-black focus:ring-[#3d3731]"
                  />
                  <span>Node mode</span>
                </label>
              </div>
              
              <ReferralTable data={referralData} />
              <Pagination />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;