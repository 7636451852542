// CustomerTable.js
import React, { useState, useEffect } from 'react';
import { CustomerRow } from './CustomerRow';
import { ChevronDown, ChevronUp, GripHorizontal } from 'lucide-react';
import { Resizable } from 're-resizable';

export const CustomerTable = ({ customers }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [height, setHeight] = useState(400);
  const [isResizing, setIsResizing] = useState(false);
  const scrollInterval = React.useRef(null);
  
  const itemsPerPage = expanded ? 10 : 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCustomers = customers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(customers.length / itemsPerPage);

  const rowHeight = 73;
  const headerHeight = 48;
  const footerHeight = 57;
  const actualRowCount = currentCustomers.length;
  const minTableHeight = headerHeight + (rowHeight * actualRowCount) + footerHeight;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setHeight(minTableHeight);
  }, [expanded, currentPage, minTableHeight]);

  return (
    <div className="flex flex-col border border-[#d8d1c7] rounded-lg bg-[#f5f1eb] outline-none focus:outline-none [&_*]:outline-none [&_*]:focus:outline-none">
      <Resizable
        size={{ width: '100%', height }}
        minHeight={minTableHeight}
        maxHeight={800}
        enable={{ 
          top: false,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
        handleComponent={{
          bottom: (
            <div className="flex items-center justify-center h-4 bg-[#d8d1c7] cursor-ns-resize w-full hover:bg-[#c5beb2] transition-colors">
              <GripHorizontal size={16} className="text-[#3d3731]" />
            </div>
          )
        }}
        onResizeStart={() => setIsResizing(true)}
        onResizeStop={(e, direction, ref, d) => {
          const newHeight = height + d.height;
          setHeight(newHeight);
          setExpanded(newHeight > minTableHeight);
          setIsResizing(false);
        }}
      >
        <div className="h-full flex flex-col">
          <div className="overflow-auto flex-1">
            <table className="w-full divide-y divide-[#d8d1c7]">
              <thead className="bg-[#d8d1c7] sticky top-0 z-10">
                <tr>
                  <th scope="col" tabIndex="-1" className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider select-none">
                    <input type="checkbox" className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" />
                  </th>
                  <th scope="col" tabIndex="-1" className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider select-none">
                    Firm
                  </th>
                  <th scope="col" tabIndex="-1" className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider select-none">
                    Status
                  </th>
                  <th scope="col" tabIndex="-1" className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider select-none">
                    Contact
                  </th>
                  <th scope="col" tabIndex="-1" className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider select-none">
                    Progress
                  </th>
                  <th scope="col" tabIndex="-1" className="px-6 py-3 text-right text-xs font-medium text-[#3d3731] uppercase tracking-wider select-none">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-[#d8d1c7]">
                {currentCustomers.map((customer, index) => (
                  <CustomerRow key={index} {...customer} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Resizable>

      <div className="px-6 py-4 border-t border-[#d8d1c7] flex items-center justify-between bg-[#f5f1eb]">
        <div className="text-sm text-[#3d3731]">
          Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, customers.length)} of {customers.length} results
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2">
            <button 
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 border border-[#a39b8f] rounded text-[#3d3731] bg-[#f5f1eb] hover:bg-[#d8d1c7] disabled:opacity-50"
            >
              Previous
            </button>
            <button 
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 border border-[#a39b8f] rounded text-[#3d3731] bg-[#f5f1eb] hover:bg-[#d8d1c7] disabled:opacity-50"
            >
              Next
            </button>
          </div>
          <button
            onClick={() => {
              setExpanded(!expanded);
              const newRowCount = !expanded ? Math.min(10, customers.length) : Math.min(4, customers.length);
              setHeight(headerHeight + (rowHeight * newRowCount) + footerHeight);
            }}
            className="flex items-center text-[#3d3731] hover:text-[#a39b8f]"
          >
            {expanded ? (
              <>
                <ChevronUp size={20} />
                <span className="ml-1">Show Less</span>
              </>
            ) : (
              <>
                <ChevronDown size={20} />
                <span className="ml-1">Show More</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;