// CustomerRow.js
import React from 'react';
import { Trash2, Edit2 } from 'lucide-react';

export const CustomerRow = ({ logo, name, website, status, contact, position, progress }) => (
  <tr tabIndex="-1" className="bg-[#f5f1eb] border-b border-[#d8d1c7] outline-none focus:outline-none [&_*]:outline-none [&_*]:focus:outline-none select-none">
    <td tabIndex="-1" className="px-6 py-4 whitespace-nowrap select-none">
      <input type="checkbox" className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" />
    </td>
    <td tabIndex="-1" className="px-6 py-4 whitespace-nowrap select-none">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={logo} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-[#3d3731]">{name}</div>
          <div className="text-sm text-[#a39b8f]">{website}</div>
        </div>
      </div>
    </td>
    <td tabIndex="-1" className="px-6 py-4 whitespace-nowrap select-none">
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
        status === 'Customer' ? 'bg-[#c5beb2] text-[#3d3731]' : 'bg-[#a39b8f] text-[#e9e4dc]'
      }`}>
        {status}
      </span>
    </td>
    <td tabIndex="-1" className="px-6 py-4 whitespace-nowrap select-none">
      <div className="text-sm text-[#3d3731]">{contact}</div>
      <div className="text-sm text-[#a39b8f]">{position}</div>
    </td>
    <td tabIndex="-1" className="px-6 py-4 whitespace-nowrap select-none">
      <div className="w-full bg-[#d8d1c7] rounded-full h-2.5">
        <div className="bg-[#3d3731] h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
      </div>
    </td>
    <td tabIndex="-1" className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium select-none">
      <button className="text-[#a39b8f] hover:text-[#3d3731] mr-2">
        <Trash2 size={20} />
      </button>
      <button className="text-[#a39b8f] hover:text-[#3d3731]">
        <Edit2 size={20} />
      </button>
    </td>
  </tr>
);

export default CustomerRow;