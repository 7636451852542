// Home.js
import React, { useState } from 'react';
import { ChevronDown, Search } from 'lucide-react';
import { StatCard } from './components/dashboard(home)/StatCard';
import { FilterPill } from './components/dashboard(home)/FilterPill';
import { CustomerTable } from './components/dashboard(home)/CustomerTable';
import { CustomerListItem } from './components/dashboard(home)/CustomerListItem';
import { PlaceholderView } from './components/dashboard(home)/PlaceholderView';

const Home = () => {
  const [currentView, setCurrentView] = useState('overview');
  
  const customers = [
    {
      logo: "/api/placeholder/40/40",
      name: "Kalka Law Group",
      website: "website.com",
      status: "Customer",
      contact: "Amanda Runnels",
      position: "Firm Manager",
      progress: 75
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Ted Law",
      website: "website.com",
      status: "Churned",
      contact: "John Doe",
      position: "Firm Manager",
      progress: 50
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Smith & Associates",
      website: "website.com",
      status: "Customer",
      contact: "Sarah Smith",
      position: "Senior Partner",
      progress: 90
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Johnson Legal",
      website: "website.com",
      status: "Customer",
      contact: "Mike Johnson",
      position: "Managing Partner",
      progress: 85
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Williams Law Firm",
      website: "website.com",
      status: "Churned",
      contact: "Emily Williams",
      position: "Office Manager",
      progress: 30
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Davis & Partners",
      website: "website.com",
      status: "Customer",
      contact: "Robert Davis",
      position: "Partner",
      progress: 65
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Anderson Legal",
      website: "website.com",
      status: "Customer",
      contact: "Lisa Anderson",
      position: "Legal Assistant",
      progress: 40
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Thompson Law",
      website: "website.com",
      status: "Churned",
      contact: "Mark Thompson",
      position: "Senior Partner",
      progress: 25
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Brown & Associates",
      website: "website.com",
      status: "Customer",
      contact: "James Brown",
      position: "Partner",
      progress: 95
    },
    {
      logo: "/api/placeholder/40/40",
      name: "Miller Law Group",
      website: "website.com",
      status: "Customer",
      contact: "Patricia Miller",
      position: "Office Manager",
      progress: 70
    }
  ];
  
  const renderView = () => {
    const viewWrapper = (content) => (
      <div className="bg-[#f5f1eb] select-none outline-none focus:outline-none [&_*]:outline-none [&_*]:focus:outline-none" tabIndex="-1">
        {content}
      </div>
    );

    switch (currentView) {
      case 'overview':
        return viewWrapper(<CustomerTable customers={customers} />);
      case 'list':
        return viewWrapper(
          <div className="select-none outline-none focus:outline-none" tabIndex="-1">
            {customers.map((customer, index) => (
              <CustomerListItem key={index} {...customer} />
            ))}
          </div>
        );
      case 'table':
      case 'segment':
      case 'custom':
        return viewWrapper(<PlaceholderView type={currentView.charAt(0).toUpperCase() + currentView.slice(1)} />);
      default:
        return null;
    }
  };

  return (
    <div className="flex-1 overflow-auto bg-[#f5f1eb] select-none outline-none focus:outline-none [&_*]:outline-none [&_*]:focus:outline-none" tabIndex="-1">
      <div className="py-8 px-10 select-none" tabIndex="-1">
        <div className="flex justify-between items-center mb-8" tabIndex="-1">
          <h1 className="text-2xl font-semibold text-[#3d3731] select-none">Customers</h1>
          <div className="flex space-x-3">
            <button className="px-4 py-2 border border-[#a39b8f] rounded-md text-[#3d3731] bg-[#d8d1c7] hover:bg-[#c5beb2] focus:outline-none">
              <span className="mr-2">↓</span> Import
            </button>
            <button className="px-4 py-2 border border-transparent rounded-md shadow-sm text-[#e9e4dc] bg-black hover:bg-[#3d3731] focus:outline-none">
              + Add Referral
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-3 gap-6 mb-8 select-none" tabIndex="-1">
          <StatCard title="Referrals" value="2,420" change="+20%" />
          <StatCard title="Referee's" value="1,210" change="+15%" />
          <StatCard title="Active Referee's" value="316" avatars={[1,2,3,4,5]} />
        </div>

        <div className="bg-[#f5f1eb] shadow rounded-lg select-none outline-none focus:outline-none" tabIndex="-1">
          <div className="px-6 py-4 border-b border-[#d8d1c7] flex justify-between items-center">
            <div className="flex space-x-4">
              {['Overview', 'Table', 'List view', 'Segment', 'Custom'].map((view) => (
                <button
                  key={view}
                  onClick={() => setCurrentView(view.toLowerCase().replace(' ', ''))}
                  className={`px-3 py-2 text-sm font-medium focus:outline-none ${
                    currentView === view.toLowerCase().replace(' ', '')
                      ? 'border-b-2 border-black text-[#3d3731]'
                      : 'text-[#a39b8f] hover:text-[#3d3731]'
                  }`}
                >
                  {view}
                </button>
              ))}
            </div>
          </div>

          <div className="px-6 py-4 flex justify-between items-center">
            <div className="flex space-x-2">
              <FilterPill>All time</FilterPill>
              <FilterPill>US, AU, +4</FilterPill>
              <button className="inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-[#f5f1eb] border border-[#a39b8f] text-[#3d3731] hover:bg-[#d8d1c7] focus:outline-none">
                More filters
                <ChevronDown size={16} className="ml-1" />
              </button>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="focus:ring-[#a39b8f] focus:border-[#a39b8f] block w-full pl-10 pr-3 py-2 border border-[#a39b8f] rounded-md leading-5 bg-[#f5f1eb] placeholder-[#a39b8f] focus:outline-none focus:placeholder-[#c5beb2] sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search size={20} className="text-[#a39b8f]" />
              </div>
            </div>
          </div>

          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default Home;